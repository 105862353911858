import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import YoutubeVideoICT from "components/Motion_Detection/SD_Card_Access/YoutubeVideoCard";
import IVCards from 'components/Motion_Detection/SD_Card_Access/IVCards';
import WebUICards from 'components/Motion_Detection/SD_Card_Access/WebUICards';
import PrimaryBox1 from 'components/Motion_Detection/SD_Card_Access/PrimaryBox1';
import PrimaryBox2 from 'components/Motion_Detection/SD_Card_Access/PrimaryBox2';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/SD_Card_Access/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "SD Card Access",
  "path": "/Motion_Detection/SD_Card_Access/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Access the Camera Recordings on the internal SD Card",
  "image": "./MD_SearchThumb_SDCardAccess.png",
  "social": "/images/Search/MD_SearchThumb_SDCardAccess.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-SD-Card_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <SEOHelmet title='SD Card Access' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Access the Camera Recordings on the internal SD Card' image='/images/Search/MD_SearchThumb_SDCardAccess.png' twitter='/images/Search/MD_SearchThumb_SDCardAccess.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/SD_Karten_Zugriff/' locationFR='/fr/Motion_Detection/SD_Card_Access/' crumbLabel="SD Card" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "sd-card-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#sd-card-access",
        "aria-label": "sd card access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SD Card Access`}</h1>
    <p>{`All current camera models use an internal (removable) SD card to record videos, alarm snapshots and whole photo series that can be used for later time lapse conversions. You can access the recorded files directly via the cameras web user interface or with an FTP client. The webUI is the quickest way to download files and screen them on your computer, tablet or smart phone from your default web browser. But FTP clients offer a few features that will be more convenient if you have to go through a large number of files from your SD card. Please be aware that the FTP client has to support the hypertext transfer protocol (HTTP) to access the SD card. Please be aware that you will only be able to download files - the FTP clients will not offer your WRITE rights on the SD storage! Below you can find a short selection of clients that will work with your INSTAR camera.`}</p>
    <p>{`For indoor models you can always remove the SD card and use it with a card reader on your computer or mobile device for easy access.`}</p>
    <OutboundLink href="https://www.instar.com/support/help-tools" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">SD Card Calculator</EuiButton></OutboundLink>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Alarm, scheduled and manual recordings will be saved to your cameras internal SD card and can be accessed using the web user interface:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/cc9d096872041338dc3b8a38d6192e0a/WQHD_WebUI_SD_Card_Viewer.gif",
        "alt": "SD Card Access"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "table-of-content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#table-of-content",
        "aria-label": "table of content permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Table of Content`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sd-card-access"
        }}>{`SD Card Access`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#table-of-content"
            }}>{`Table of Content`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#smartphone-apps"
            }}>{`Smartphone Apps`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#instar-camera-tool"
            }}>{`INSTAR Camera Tool`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#third-party-software"
            }}>{`Third-Party Software`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#older-camera-models"
            }}>{`Older Camera Models`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "smartphone-apps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smartphone-apps",
        "aria-label": "smartphone apps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smartphone Apps`}</h2>
    <IVCards mdxType="IVCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The HD camera models allow you to record videos and snapshots to the internal SD card. The easiest way to access your alarm recordings is to use our smartphone apps for `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPhone/Recording/"
      }}>{`iOS`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/Recording/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision_Windows-Phone/Recording/"
      }}>{`Windows Phone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision_Metro/Recording/"
      }}>{`WINDOWS 8 / 10`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/InstarVision_Blackberry"
      }}>{`Blackberry`}</a>{`.`}</p>
    <h2 {...{
      "id": "instar-camera-tool",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-camera-tool",
        "aria-label": "instar camera tool permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Camera Tool`}</h2>
    <YoutubeVideoICT mdxType="YoutubeVideoICT" />
    <p>{`The new INSTAR Camera Tool for Windows and MacOS is a user-friendly and comprehensive solution for owners of INSTAR 2K+ series surveillance cameras. With this tool you can now effortlessly keep track of your cameras, perform updates, change settings and read out important data.`}</p>
    <p>{`An outstanding feature of the tool is the ability to check for updates for your cameras and update them directly through the tool. You will receive a notification when an update is available, and then you can simply click on the update option to start the process. The tool also allows you to update multiple cameras at the same time, which saves time and effort. After the update, you can conveniently restart the cameras via the tool.`}</p>
    <p>{`Another useful feature of the INSTAR Camera Tool is the ability to change the name of your cameras. This is especially useful to better organize your cameras and set individual labels for each location or room.`}</p>
    <p>{`Moreover, the tool allows you to download video recordings from the SD card of your cameras. You have the option to select recordings by day or individually and save them directly to your computer. This makes managing your video recordings much easier and gives you quick access to important events.`}</p>
    <p>{`The INSTAR Camera Tool also offers the possibility to do the IP configuration of your cameras. You can conveniently change the IP address, subnet, gateway and other network settings via the tool. This allows easy customization to your individual network requirements.`}</p>
    <p>{`In addition, you can use the tool to read out important data from your cameras. This includes information such as the P2P address, the firmware version, the storage space on the SD card and other relevant data. This gives you a quick overview of the status of your cameras and facilitates troubleshooting when needed.`}</p>
    <p>{`The new INSTAR Camera Tool for Windows and MacOS is a powerful and user-friendly tool that gives you full control over your INSTAR 2K+ series surveillance cameras. It greatly simplifies updating, configuring and managing your cameras and ensures that you always have an overview and can use your cameras optimally.`}</p>
    <p>{`You can find the new tool on the INSTAR website or directly here: `}<a parentName="p" {...{
        "href": "/en/Downloads/Desktop_Software/Instar_Camera_Tool/"
      }}>{`Download`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "third-party-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#third-party-software",
        "aria-label": "third party software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Third-Party Software`}</h2>
    <PrimaryBox1 mdxType="PrimaryBox1" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox2 mdxType="PrimaryBox2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "older-camera-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#older-camera-models",
        "aria-label": "older camera models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Older Camera Models`}</h2>
    <WebUICards mdxType="WebUICards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      